/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AddToQueueOutlinedIcon from "@material-ui/icons/AddToQueueOutlined";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SignalCellular0BarIcon from "@material-ui/icons/SignalCellular0Bar";
import SignalCellular1BarIcon from "@material-ui/icons/SignalCellular1Bar";
import { Android } from "@material-ui/icons";
import lod_ from "lodash";

import SignalCellular2BarIcon from "@material-ui/icons/SignalCellular2Bar";
import SignalCellular3BarIcon from "@material-ui/icons/SignalCellular3Bar";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import SignalCellularConnectedNoInternet4BarIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect } from "react";
import useStyles from "../../CockpitStyleJs/styleFilter";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import GetFlag from "../../../../helpers/GetFlag";
import GetChannelImage from "../../../../helpers/GetChannelImage";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import C from "../../../../constants/cockpit.js";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import DateFilter from "./DateFilter";
import GroupFilter from "./GroupFilter";
import { LCFilter } from "./LiveChatFilter";
import { FiltersActions } from "./FiltersActions";
import IntentFilter from "./IntentFilter";
import UserFilter from "./UserFilter";
import { ListConvsAction } from "./ListConvsAction";
import DirectionFilter from "./DirectionFilter";
import PathContext from "./PathContext";
import { Chip, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import ViewConversationDialog from "pages/Cockpit/CockpitDialog/LastViewConversation";

export default ({
	handleChangeAscFilter,
	possibleChannelFilter,
	handleConvChannelFilter,
	possibleLanguageFilter,
	handleConvLanguageFilter,
	handleConvPriorityFilter,
	handleConvSentimentFilter,
	handleConvSubstateFilter,
	role,
	handleOpenUserFilterDialog,
	handleChangeSupervisorFilter,
	handleChangeAgentFilter
}) => {
	const {
		convIntentFilter,
		convUserFilter,
		convPriorityFilter,
		convSentimentFilter,
		convSubstateFilter,
		convLanguageFilter,
		convChannelFilter,
		convAscFilter,
		leftTab,
		convSupervisorFilter,
		convAgentFilter,
		isCold,
		isCockpitArchived
	} = useSelector(state => state.cockpit);
	const { channels, cockpit, dictionary } = useSelector(state => state.assistantconfig);
	const classes = useStyles();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [openDialogView, setOpenDialogView] = React.useState(false);

	const [openChannel, setOpenChannel] = React.useState(false);
	const [openLanguage, setOpenLanguage] = React.useState(false);
	const [openPriority, setOpenPriority] = React.useState(false);
	const [openSentiment, setOpenSentiment] = React.useState(false);
	const [openSubstate, setOpenSubstate] = React.useState(false);
	const [hoveredChannel, setHoveredChannel] = React.useState(false);
	const [hoveredLanguage, setHoveredLanguage] = React.useState(false);
	const [hoveredPriority, setHoveredPriority] = React.useState(false);
	const [hoveredSentiment, setHoveredSentiment] = React.useState(false);
	const [openConv, setOpenConv] = React.useState(false);
	const [openFilterChannel, setOpenFilterChannel] = React.useState(false);

	const [hoveredConv, setHoveredConv] = React.useState(false);
	const [hoveredSubstate, setHoveredSubstate] = React.useState(false);
	const [isBotNul, setIsBotNul] = React.useState(false);

	const anchorRefChannel = React.useRef(null);
	const anchorRefLanguage = React.useRef(null);
	const anchorRefPriority = React.useRef(null);
	const anchorRefSentiment = React.useRef(null);
	const anchorRefConv = React.useRef(null);
	const anchorRefSubstate = React.useRef(null);
	const dispatch = useDispatch();
	let channelSet = (convChannelFilter !== null) === true;
	let languageSet = (convLanguageFilter !== null) === true;
	let prioritySet = convPriorityFilter > 0 === true;
	let sentimentSet = (convSentimentFilter !== null) === true;
	let userSet = convUserFilter.length !== 0;

	useEffect(() => {
		if (hoveredChannel) {
			const timer = hoveredChannel && setTimeout(handleToggleChannel, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenChannel(false);
		}
	}, [hoveredChannel]);

	useEffect(() => {
		if (hoveredLanguage) {
			const timer = hoveredLanguage && setTimeout(handleToggleLanguage, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenLanguage(false);
		}
	}, [hoveredLanguage]);

	useEffect(() => {
		if (hoveredPriority) {
			const timer = hoveredPriority && setTimeout(handleTogglePriority, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenPriority(false);
		}
	}, [hoveredPriority]);
	useEffect(() => {
		if (hoveredSentiment) {
			const timer = hoveredSentiment && setTimeout(handleToggleSentiment, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenSentiment(false);
		}
	}, [hoveredSentiment]);

	useEffect(() => {
		if (hoveredConv) {
			const timer = hoveredConv && setTimeout(handleToggleConv, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenConv(false);
		}
	}, [hoveredConv]);

	useEffect(() => {
		if (hoveredSubstate) {
			const timer = hoveredSubstate && setTimeout(handleToggleSubstate, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenSubstate(false);
		}
	}, [hoveredSubstate]);

	//All channel method to handle the dropdown etc...
	const handleToggleChannel = () => {
		setOpenChannel(prevOpen => !prevOpen);
	};
	const handleCloseChannel = event => {
		if (anchorRefChannel.current && anchorRefChannel.current.contains(event.target)) {
			return;
		}
		setOpenChannel(false);
	};
	function handleListKeyDownChannel(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenChannel(false);
		}
	}
	const prevOpenChannel = React.useRef(openChannel);
	React.useEffect(() => {
		if (prevOpenChannel.current === true && openChannel === false) {
			anchorRefChannel.current.focus();
		}
		prevOpenChannel.current = openChannel;
	}, [openChannel]);
	//end channel

	//All language method to handle the dropdown etc...
	const handleToggleLanguage = () => {
		setOpenLanguage(prevOpen => !prevOpen);
	};
	const handleCloseLanguage = event => {
		if (anchorRefLanguage.current && anchorRefLanguage.current.contains(event.target)) {
			return;
		}
		setOpenLanguage(false);
	};
	function handleListKeyDownLanguage(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenLanguage(false);
		}
	}
	const prevOpenLanguage = React.useRef(openLanguage);
	React.useEffect(() => {
		if (prevOpenLanguage.current === true && openLanguage === false) {
			anchorRefLanguage.current.focus();
		}
		prevOpenLanguage.current = openLanguage;
	}, [openLanguage]);
	//end language

	// All priority method to handle the dropdown etc...
	const handleTogglePriority = () => {
		setOpenPriority(prevOpen => !prevOpen);
	};
	const handleClosePriority = event => {
		if (anchorRefPriority.current && anchorRefPriority.current.contains(event.target)) {
			return;
		}
		setOpenPriority(false);
	};
	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenPriority(false);
		}
	}
	const prevOpen = React.useRef(openPriority);

	React.useEffect(() => {
		if (prevOpen.current === true && openPriority === false) {
			anchorRefPriority.current.focus();
		}

		prevOpen.current = openPriority;
	}, [openPriority]);
	//end priority

	//All substate method to handle the dropdown etc...
	const handleToggleSubstate = () => {
		setOpenSubstate(prevOpen => !prevOpen);
	};
	const handleCloseSubstate = event => {
		if (anchorRefSubstate.current && anchorRefSubstate.current.contains(event.target)) {
			return;
		}
		setOpenSubstate(false);
	};
	function handleListKeyDownSubstate(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenSubstate(false);
		}
	}
	const prevOpenSubstate = React.useRef(openSubstate);
	React.useEffect(() => {
		if (prevOpenSubstate.current === true && openSubstate === false) {
			anchorRefSubstate.current.focus();
		}

		prevOpenSubstate.current = prevOpenSubstate;
	}, [prevOpenSubstate]);
	//end substate

	//All sentiment method to handle the dropdown etc...
	const handleToggleSentiment = () => {
		setOpenSentiment(prevOpen => !prevOpen);
	};

	const handleToggleConv = () => {
		setOpenConv(prevOpen => !prevOpen);
	};

	const handleCloseSentiment = event => {
		if (anchorRefSentiment.current && anchorRefSentiment.current.contains(event.target)) {
			return;
		}
		setOpenSentiment(false);
	};

	const handleCloseConv = event => {
		if (anchorRefConv.current && anchorRefConv.current.contains(event.target)) {
			return;
		}
		setOpenConv(false);
	};

	function handleListKeyDownSentiment(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenSentiment(false);
		}
	}

	function handleListKeyDownConv(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenConv(false);
		}
	}

	const prevOpenSentiment = React.useRef(openSentiment);

	React.useEffect(() => {
		if (prevOpenSentiment.current === true && openSentiment === false) {
			anchorRefSentiment.current.focus();
		}
		prevOpenSentiment.current = openSentiment;
	}, [openSentiment]);

	const prevOpenConv = React.useRef(openConv);

	React.useEffect(() => {
		if (prevOpenConv.current === true && openConv === false) {
			anchorRefConv.current.focus();
		}
		prevOpenConv.current = openConv;
	}, [openConv]);
	//end sentiment

	//find the right icon for display channel filter
	const ChannelIconActive = props => {
		let channel = convChannelFilter;

		if (!channel) {
			return (
				<img
					src={GetChannelImage(channels, "ML")}
					alt="default_channel"
					width="20"
					height="20"
					className="logo-channel"
					style={{
						filter: "saturate(0) contrast(30%) brightness(1.5)",
						width: "20px",
						height: "20px"
					}}
				/>
			);
		} else {
			return (
				<img
					src={GetChannelImage(channels, channel.code.toUpperCase())}
					alt="current_logo"
					width="20"
					height="20"
					className="logo-channel"
				/>
			);
		}
	};
	//end channel

	//find the right icon for display language filter
	const LanguageIconActive = props => {
		let language = convLanguageFilter;

		if (!language) {
			return (
				<img
					src={GetFlag("FR")}
					alt="default_language"
					width="20"
					height="20"
					style={{
						filter: "saturate(0) contrast(20%) brightness(1.5)",
						width: "20px",
						height: "20px",
						objectFit: "contain"
					}}
				/>
			);
		} else {
			return (
				<img
					src={GetFlag(language.toUpperCase())}
					alt="Filter_flag"
					width="20"
					height="20"
					style={{ objectFit: "contain" }}
					onClick={() => setConvLanguageFilter(null)}
				/>
			);
		}
	};
	//end language

	//find the right icon for display priority filter
	const PriorityIconActive = props => {
		let priority = convPriorityFilter;
		switch (priority) {
			case 1:
				return (
					<SignalCellular0BarIcon
						fontSize="small"
						style={{ color: "black" }}
						onClick={() => setConvPriorityFilter(null)}
					/>
				);
			case 2:
				return (
					<SignalCellular1BarIcon
						fontSize="small"
						style={{ color: "black" }}
						onClick={() => setConvPriorityFilter(null)}
					/>
				);
			case 3:
				return (
					<SignalCellular2BarIcon
						fontSize="small"
						style={{ color: "blue" }}
						onClick={() => setConvPriorityFilter(null)}
					/>
				);
			case 4:
				return (
					<SignalCellular3BarIcon
						fontSize="small"
						style={{ color: "orange" }}
						onClick={() => setConvPriorityFilter(null)}
					/>
				);
			case 5:
				return (
					<SignalCellularConnectedNoInternet4BarIcon
						fontSize="small"
						style={{ color: "red" }}
						onClick={() => setConvPriorityFilter(null)}
					/>
				);
			default:
				return (
					<SignalCellularConnectedNoInternet4BarIcon
						fontSize="small"
						style={{ color: "grey" }}
						onClick={() => setConvPriorityFilter(null)}
					/>
				);
		}
	};

	//find the right icon for display sentiment filter
	const SentimentIconActive = props => {
		let sentiment = convSentimentFilter;
		switch (sentiment) {
			case "negative":
				return (
					<SentimentVeryDissatisfiedIcon
						fontSize="small"
						style={{ color: "red" }}
						onClick={() => setConvSentimentFilter(null)}
					/>
				);
			case "neutral":
				return (
					<SentimentSatisfiedIcon
						fontSize="small"
						style={{ color: "black" }}
						onClick={() => setConvSentimentFilter(null)}
					/>
				);
			case "positive":
				return (
					<SentimentSatisfiedAltIcon
						fontSize="small"
						style={{ color: "green" }}
						onClick={() => setConvSentimentFilter(null)}
					/>
				);
			default:
				return (
					<SentimentSatisfiedIcon onClick={() => setConvSentimentFilter(null)} fontSize="small" />
				);
		}
	};

	//find the right icon for display status filter
	const SubStateIconActive = props => {
		let status = convSubstateFilter;
		if (lod_.isObject(status)) {
			switch (status?.header) {
				case "isEscalated":
					return (
						<TrendingUpIcon
							onClick={() => setConvSubstateFilter(null)}
							fontSize="small"
							style={{
								color: "black"
							}}
						/>
					);
				case "escalateDone":
					return (
						<TrendingUpIcon
							onClick={() => setConvSubstateFilter(null)}
							fontSize="small"
							style={{
								color: "green"
							}}
						/>
					);
				default:
					return (
						<AddToQueueOutlinedIcon fontSize="small" onClick={() => setConvSubstateFilter(null)} />
					);
			}
		} else {
			switch (status) {
				case "waiting_client":
					return (
						<AvTimerOutlinedIcon
							fontSize="small"
							style={{ color: "black" }}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "waiting_action":
					return (
						<BallotOutlinedIcon
							fontSize="small"
							style={{ color: "black" }}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "resolved":
					return (
						<DoneOutlineOutlinedIcon
							fontSize="small"
							style={{ color: "green" }}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "watchlist":
					return (
						<RemoveRedEyeOutlinedIcon
							fontSize="small"
							style={{ color: "orange" }}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "expecting_answer":
					return (
						<HelpOutlineIcon
							fontSize="small"
							style={{ color: "blue" }}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "reopen":
					return (
						<SettingsBackupRestoreIcon
							fontSize="small"
							style={{
								color: "black"
							}}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "error":
					return (
						<ErrorOutlineIcon
							fontSize="small"
							style={{ color: "red" }}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);
				case "sending":
					return (
						<ScheduleIcon
							fontSize="small"
							style={{
								color: "black"
							}}
							onClick={() => setConvSubstateFilter(null)}
						/>
					);

				default:
					return (
						<AddToQueueOutlinedIcon fontSize="small" onClick={() => setConvSubstateFilter(null)} />
					);
			}
		}
	};

	//find the right icon for display connected filter

	const setConvChannelFilter = value => {
		dispatch({
			type: C.SET_CONV_CHANNEL_FILTER,
			payload: value
		});
	};

	const setConvLanguageFilter = value => {
		dispatch({
			type: C.SET_CONV_LANGUAGE_FILTER,
			payload: value
		});
	};

	const setConvPriorityFilter = value => {
		dispatch({
			type: C.SET_CONV_PRIORITY_FILTER,
			payload: value
		});
	};

	const setConvSentimentFilter = value => {
		dispatch({
			type: C.SET_CONV_SENTIMENT_FILTER,
			payload: value
		});
	};

	const setConvSubstateFilter = value => {
		dispatch({
			type: C.SET_CONV_SUBSTATE_FILTER,
			payload: value
		});
	};

	const setChangeSupervisorFilter = value => {
		switch (value) {
			case 0:
				setIsBotNul(true);
				handleChangeSupervisorFilter(false, true);
				break;

			case 1:
				handleChangeSupervisorFilter(false, false);
				setIsBotNul(false);
				break;

			case 2:
				handleChangeSupervisorFilter(true, false);
				setIsBotNul(false);
				break;
		}
	};

	const setChangeUserFilter = value => {
		switch (value) {
			case 0:
				setIsBotNul(true);
				handleChangeAgentFilter(false, true);
				break;

			case 1:
				handleChangeAgentFilter(false, false);
				setIsBotNul(false);
				break;

			case 2:
				handleChangeAgentFilter(true, false);
				setIsBotNul(false);
				break;
		}
	};

	const handleCloseDialogView = () => {
		setOpenDialogView(false);
	};

	return (
		<Box display="flex" flexWrap="wrap" alignItems="center" mb={1} mx={0.5}>
			{/* Filtre par ordre chronologique */}
			{!isCold && (
				<Tooltip title={i18n.t("COC.TimeFilter")} placement="top">
					<Box className={classes.filterButton} mt={1}>
						<ToggleButtonGroup
							value={convAscFilter}
							exclusive
							onChange={() => handleChangeAscFilter(!convAscFilter)}
							size="small"
						>
							<ToggleButton value={true}>
								<SortRoundedIcon fontSize="small" style={convAscFilter ? { color: "black" } : {}} />
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
				</Tooltip>
			)}
			{/* Filtre Calendrier/date */}
			<DateFilter />
			{/* Filtre dropdown channels */}
			{(possibleChannelFilter.length > 0 || convChannelFilter !== null) && !isCold && (
				<Tooltip title={hoveredChannel ? "" : i18n.t("COC.channelFilter")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredChannel(true)}
						onMouseLeave={() => {
							if (openFilterChannel !== true) {
								setHoveredChannel(false);
							}
						}}
					>
						<ToggleButton
							onClick={() => setConvChannelFilter(null)}
							aria-haspopup="menu"
							aria-controls={openChannel ? "menu-list-grow-channel" : undefined}
							ref={anchorRefChannel}
							value={openChannel}
							size="small"
							style={channelSet ? { background: "#0000001f" } : {}}
						>
							<ChannelIconActive
								convChannelFilter={convChannelFilter}
								setConvChannelFilter={setConvChannelFilter}
							/>
						</ToggleButton>
						<Popper
							style={{ width: possibleChannelFilter.length > 5 ? "15%" : "auto" }}
							open={openChannel}
							anchorEl={anchorRefChannel.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal={possibleChannelFilter.length > 5 ? false : true}
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseChannel}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openChannel}
										id="menu-list-grow-channel"
										onKeyDown={handleListKeyDownChannel}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={convChannelFilter?.code}
												exclusive
												onChange={handleConvChannelFilter}
												size="small"
											>
												{possibleChannelFilter.length <= 5 &&
													possibleChannelFilter
														.sort((a, b) => {
															// Priorité aux objets ayant `bool: true`
															if (a.favorite === true && b.favorite !== true) return -1;
															if (b.favorite === true && a.favorite !== true) return 1;

															// Ensuite, tri alphabétique sur `data`
															return a.name.localeCompare(b.name);
														})
														.map(channel => {
															return (
																<Tooltip title={channel.name} key={channel.code} placement="right">
																	<ToggleButton
																		value={channel}
																		style={
																			convChannelFilter?.code === channel.code
																				? { background: "#0000001f" }
																				: {}
																		}
																	>
																		<img
																			src={GetChannelImage(
																				channels,
																				channel.code.toUpperCase(),
																				channel
																			)}
																			alt="logo_dropdown"
																			width="20"
																			height="20"
																			className="logo-channel"
																		/>
																	</ToggleButton>
																</Tooltip>
															);
														})}

												{possibleChannelFilter.length > 5 && (
													<Grid container>
														{possibleChannelFilter
															.sort((a, b) => {
																// Priorité aux objets ayant `bool: true`
																if (a.favorite === true && b.favorite !== true) return -1;
																if (b.favorite === true && a.favorite !== true) return 1;

																// Ensuite, tri alphabétique sur `data`
																return a.name.localeCompare(b.name);
															})
															.slice(0, 8)
															.map(channel => {
																return (
																	<Grid item xs={4} key={channel.code}>
																		<Tooltip
																			title={channel.name}
																			key={channel.code}
																			placement="right"
																		>
																			<ToggleButton
																				value={channel}
																				onChange={event => handleConvChannelFilter(event, channel)}
																				style={
																					convChannelFilter?.code === channel.code
																						? {
																								background: "#0000001f",
																								width: "100%",
																								height: "auto"
																							}
																						: { width: "100%", height: "auto", borderRadius: "8px" }
																				}
																			>
																				<img
																					src={GetChannelImage(
																						channels,
																						channel.code.toUpperCase(),
																						channel
																					)}
																					alt="logo_dropdown"
																					width="25"
																					height="25"
																					className="logo-channel"
																				/>
																			</ToggleButton>
																		</Tooltip>
																	</Grid>
																);
															})}
														{possibleChannelFilter.length > 8 && (
															<Grid item xs={4}>
																<Tooltip title={i18n.t("COC.otherChannel")} placement="right">
																	<ToggleButton
																		onChange={() => setOpenFilterChannel(true)}
																		style={{ width: "100%", height: "100%", borderRadius: "8px" }}
																	>
																		...
																	</ToggleButton>
																</Tooltip>
															</Grid>
														)}
													</Grid>
												)}

												<Dialog
													open={openFilterChannel}
													onClose={() => {
														setOpenFilterChannel(false);
														setHoveredChannel(false);
													}}
													aria-labelledby="form-dialog-title"
													fullWidth
													maxWidth="md"
												>
													<DialogTitle id="alert-dialog-title">
														{i18n.t("COC.channelFilter")}
													</DialogTitle>
													<DialogContent>
														<span
															style={{
																display: "flex",
																flexWrap: "wrap",
																width: "100%",
																paddingBottom: "2%"
															}}
														>
															{possibleChannelFilter
																.sort((a, b) => {
																	// Priorité aux objets ayant `bool: true`
																	if (a.favorite === true && b.favorite !== true) return -1;
																	if (b.favorite === true && a.favorite !== true) return 1;

																	// Ensuite, tri alphabétique sur `data`
																	return a.name.localeCompare(b.name);
																})
																.map(channel => {
																	return (
																		<Box
																			key={channel.code}
																			style={{
																				paddingRight: "2%",
																				paddingTop: "2%"
																			}}
																		>
																			<Chip
																				label={channel.name}
																				color={"default"}
																				icon={
																					<img
																						src={GetChannelImage(
																							channels,
																							channel.code.toUpperCase(),
																							channel
																						)}
																						alt="logo_dropdown"
																						width="25"
																						height="25"
																						className="logo-channel"
																					/>
																				}
																				clickable={true}
																				onClick={event => {
																					handleConvChannelFilter(event, channel);
																					setHoveredChannel(false);
																					setOpenFilterChannel(false);
																				}}
																			/>
																		</Box>
																	);
																})}
														</span>
													</DialogContent>
												</Dialog>

												{/* OLD FORTMAT OF CHANNELS FILTER
												{possibleChannelFilter.map(channel => {
													return (
														<Tooltip title={channel.name} key={channel.code} placement="right">
															<ToggleButton
																value={channel}
																style={
																	convChannelFilter?.code === channel.code
																		? { background: "#0000001f" }
																		: {}
																}
															>
																<img
																	src={GetChannelImage(
																		channels,
																		channel.code.toUpperCase(),
																		channel
																	)}
																	alt="logo_dropdown"
																	width="20"
																	height="20"
																	className="logo-channel"
																/>
															</ToggleButton>
														</Tooltip>
													);
												})} */}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
			{/* Filtre dropdown languages */}
			{(possibleLanguageFilter.length > 0 || convLanguageFilter !== null) && !isCold && (
				<Tooltip title={i18n.t("COC.languageFilter")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredLanguage(true)}
						onMouseLeave={() => setHoveredLanguage(false)}
					>
						<ToggleButton
							onClick={() => setConvLanguageFilter(null)}
							aria-haspopup="menu"
							aria-controls={openLanguage ? "menu-list-grow-language" : undefined}
							ref={anchorRefLanguage}
							value={openLanguage}
							size="small"
							style={languageSet ? { background: "#0000001f" } : {}}
						>
							<LanguageIconActive
								convLanguageFilter={convLanguageFilter}
								setConvLanguageFilter={setConvLanguageFilter}
							/>
						</ToggleButton>
						<Popper
							open={openLanguage}
							anchorEl={anchorRefLanguage.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseLanguage}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openLanguage}
										id="menu-list-grow-language"
										onKeyDown={handleListKeyDownLanguage}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={convLanguageFilter}
												exclusive
												onChange={handleConvLanguageFilter}
												size="small"
											>
												{possibleLanguageFilter.map(language => {
													return (
														<ToggleButton value={language} key={language}>
															<img
																src={GetFlag(language.toUpperCase())}
																alt="Filter_flag"
																width="20"
																height="20"
															/>
														</ToggleButton>
													);
												})}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
			{/*  Filtre dropdown priorité  */}
			{!isCold && (
				<Tooltip title={i18n.t("COC.priorityFilter")} placement="top">
					<Box
						className={classes.filterButton}
						onMouseEnter={() => setHoveredPriority(true)}
						onMouseLeave={() => setHoveredPriority(false)}
						mt={1}
					>
						<ToggleButton
							onClick={() => setConvPriorityFilter(null)}
							aria-haspopup="menu"
							aria-controls={openPriority && "menu-list-grow-priority"}
							ref={anchorRefPriority}
							value={openPriority}
							size="small"
							style={prioritySet ? { background: "#0000001f" } : {}}
						>
							<PriorityIconActive
								convPriorityFilter={convPriorityFilter}
								setConvPriorityFilter={setConvPriorityFilter}
							/>
						</ToggleButton>
						<Popper
							open={openPriority}
							anchorEl={anchorRefPriority.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleClosePriority}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openPriority}
										id="menu-list-grow-priority"
										onKeyDown={handleListKeyDown}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={convPriorityFilter}
												exclusive
												onChange={handleConvPriorityFilter}
												size="small"
											>
												<Tooltip title={i18n.t("convItemPriority.minimal")} placement="right">
													<ToggleButton value={1}>
														<SignalCellular0BarIcon
															fontSize="small"
															style={{
																color: "black"
															}}
														/>
													</ToggleButton>
												</Tooltip>
												<Tooltip title={i18n.t("convItemPriority.low")} placement="right">
													<ToggleButton value={2}>
														<SignalCellular1BarIcon
															fontSize="small"
															style={{
																color: "black"
															}}
														/>
													</ToggleButton>
												</Tooltip>
												<Tooltip title={i18n.t("convItemPriority.medium")} placement="right">
													<ToggleButton value={3}>
														<SignalCellular2BarIcon
															fontSize="small"
															style={{
																color: "blue"
															}}
														/>
													</ToggleButton>
												</Tooltip>
												<Tooltip title={i18n.t("convItemPriority.high")} placement="right">
													<ToggleButton value={4}>
														<SignalCellular3BarIcon
															fontSize="small"
															style={{
																color: "orange"
															}}
														/>
													</ToggleButton>
												</Tooltip>
												<Tooltip title={i18n.t("convItemPriority.critical")} placement="right">
													<ToggleButton value={5}>
														<SignalCellularConnectedNoInternet4BarIcon
															fontSize="small"
															style={{ color: "red" }}
														/>
													</ToggleButton>
												</Tooltip>
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
			{/* Filtre dropdown sentiment */}
			{!isCold && (
				<Tooltip title={i18n.t("COC.feelingFilter")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredSentiment(true)}
						onMouseLeave={() => setHoveredSentiment(false)}
					>
						<ToggleButton
							onClick={() => setConvSentimentFilter(null)}
							aria-haspopup="menu"
							aria-controls={openSentiment ? "menu-list-grow-sentiment" : undefined}
							ref={anchorRefSentiment}
							value={openSentiment}
							size="small"
							style={sentimentSet ? { background: "#0000001f" } : {}}
						>
							<SentimentIconActive
								convSentimentFilter={convSentimentFilter}
								setConvSentimentFilter={setConvSentimentFilter}
							/>
						</ToggleButton>
						<Popper
							open={openSentiment}
							anchorEl={anchorRefSentiment.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseSentiment}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openSentiment}
										id="menu-list-grow-sentiment"
										onKeyDown={handleListKeyDownSentiment}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={convSentimentFilter}
												exclusive
												onChange={handleConvSentimentFilter}
												size="small"
											>
												<Tooltip title={i18n.t("convItemSentiment.negative")} placement="right">
													<ToggleButton value="negative">
														<SentimentVeryDissatisfiedIcon
															fontSize="small"
															style={{ color: "red" }}
														/>
													</ToggleButton>
												</Tooltip>
												<Tooltip title={i18n.t("convItemSentiment.neutral")} placement="right">
													<ToggleButton value="neutral">
														<SentimentSatisfiedIcon
															fontSize="small"
															style={{
																color: "black"
															}}
														/>
													</ToggleButton>
												</Tooltip>
												<Tooltip title={i18n.t("convItemSentiment.positive")} placement="right">
													<ToggleButton value="positive">
														<SentimentSatisfiedAltIcon
															fontSize="small"
															style={{
																color: "green"
															}}
														/>
													</ToggleButton>
												</Tooltip>
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
			{/* Filtre statut */}
			{leftTab !== 0 && !isCold && (
				<Tooltip title={i18n.t("COC.statusFilter")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredSubstate(true)}
						onMouseLeave={() => setHoveredSubstate(false)}
					>
						<ToggleButton
							onClick={() => setConvSubstateFilter(null)}
							aria-haspopup="menu"
							aria-controls={openSubstate ? "menu-list-grow-substate" : undefined}
							ref={anchorRefSubstate}
							value={openSubstate}
							size="small"
						>
							<SubStateIconActive
								convSubstateFilter={convSubstateFilter}
								setConvSubstateFilter={setConvSubstateFilter}
							/>
						</ToggleButton>
						<Popper
							open={openSubstate}
							anchorEl={anchorRefSubstate.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{({}) => (
								<ClickAwayListener onClickAway={handleCloseSubstate}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openSubstate}
										id="menu-list-grow-sentiment"
										onKeyDown={handleListKeyDownSubstate}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={convSubstateFilter}
												exclusive
												onChange={handleConvSubstateFilter}
												size="small"
											>
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("convItemRender.waitingClient")} placement="right">
														<ToggleButton value="waiting_client">
															<AvTimerOutlinedIcon
																fontSize="small"
																style={{
																	color: "black"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("convItemRender.waitingAction")} placement="right">
														<ToggleButton value="waiting_action">
															<BallotOutlinedIcon
																fontSize="small"
																style={{
																	color: "black"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("convItemRender.resolved")} placement="right">
														<ToggleButton value="resolved">
															<DoneOutlineOutlinedIcon
																fontSize="small"
																style={{
																	color: "green"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("convItemRender.watchlist")} placement="right">
														<ToggleButton value="watchlist">
															<RemoveRedEyeOutlinedIcon
																fontSize="small"
																style={{
																	color: "orange"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip
														title={i18n.t("convItemRender.expectingAnswer")}
														placement="right"
													>
														<ToggleButton value="expecting_answer">
															<HelpOutlineIcon
																fontSize="small"
																style={{
																	color: "blue"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("convItemRender.error")} placement="right">
														<ToggleButton value="error">
															<ErrorOutlineIcon fontSize="small" style={{ color: "red" }} />
														</ToggleButton>
													</Tooltip>
												)}
												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("convItemRender.sending")} placement="right">
														<ToggleButton value="sending">
															<ScheduleIcon
																fontSize="small"
																style={{
																	color: "black"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
												<Tooltip title={i18n.t("convItemRender.reopen")} placement="right">
													<ToggleButton value="reopen">
														<SettingsBackupRestoreIcon
															fontSize="small"
															style={{
																color: "black"
															}}
														/>
													</ToggleButton>
												</Tooltip>

												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("COC.ApplyEscalte")} placement="right">
														<ToggleButton
															value={
																convSubstateFilter?.header === "isEscalated"
																	? null
																	: { header: "isEscalated", value: true }
															}
														>
															<TrendingUpIcon
																fontSize="small"
																style={{
																	color: "black"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}

												{(leftTab === 1 || leftTab === 2) && (
													<Tooltip title={i18n.t("COC.escalteDone")} placement="right">
														<ToggleButton
															value={
																convSubstateFilter?.header === "escalateDone"
																	? null
																	: { header: "escalateDone", value: true }
															}
														>
															<TrendingUpIcon
																fontSize="small"
																style={{
																	color: "green"
																}}
															/>
														</ToggleButton>
													</Tooltip>
												)}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
			{/* Filtre Intentions */}
			<IntentFilter role={role} />
			{/* Filtre utilisateurs */}
			{(!convSupervisorFilter || !convAgentFilter) && (leftTab !== 0 || isCockpitArchived) ? (
				<UserFilter role={role} />
			) : (
				<></>
			)}
			{/* Filtre Groupes */}
			<GroupFilter role={role} />
			{/* Filtre pour afficher ou non toutes les conversations */}
			{(role === "supervisor" || role === "soloUser") &&
				!isCold &&
				(leftTab !== 0 || isCockpitArchived) && (
					<Tooltip title={i18n.t("COC.supervisorFilterConv")} placement="top">
						<Box
							className={classes.filterButton}
							mt={1}
							onMouseEnter={() => setHoveredConv(true)}
							onMouseLeave={() => setHoveredConv(false)}
						>
							<ToggleButton
								aria-haspopup="menu"
								aria-controls={openConv ? "menu-list-grow-actions" : undefined}
								ref={anchorRefConv}
								value={openConv}
								size="small"
								style={{
									backgroundColor: convSupervisorFilter || isBotNul ? "rgba(0, 0, 0, 0.15)" : null
								}}
							>
								{!convSupervisorFilter && !isBotNul && (
									<SupervisedUserCircleRoundedIcon fontSize="small" />
								)}

								{convSupervisorFilter && !isBotNul && (
									<AccountCircleRoundedIcon fontSize="small" style={{ color: "black" }} />
								)}

								{isBotNul && <Android fontSize="small" style={{ color: "red" }} />}
							</ToggleButton>
							<Popper
								open={openConv}
								anchorEl={anchorRefConv.current}
								className={classes.dropDown}
								role={undefined}
								transition
								disablePortal
							>
								{() => (
									<ClickAwayListener onClickAway={handleCloseConv}>
										<MenuList
											mt={0}
											mb={0}
											pt={0}
											autoFocusItem={openConv}
											id="menu-list-grow-actions"
											onKeyDown={handleListKeyDownConv}
										>
											<Paper>
												<ToggleButtonGroup
													orientation="vertical"
													exclusive
													size="small"
													background="#00000000"
													value={isBotNul ? 0 : convSupervisorFilter ? 1 : 2}
												>
													<Tooltip title={i18n.t("COC.supervisorFilterActive")} placement="right">
														<ToggleButton
															value={2}
															onClick={() => setChangeSupervisorFilter(2)}
															style={{
																color: convSupervisorFilter && !isBotNul ? "black" : null,
																backgroundColor:
																	convSupervisorFilter && !isBotNul ? "rgba(0, 0, 0, 0.15)" : null
															}}
														>
															<AccountCircleRoundedIcon fontSize="small" />
														</ToggleButton>
													</Tooltip>
													<Tooltip title={i18n.t("COC.supervisorFilterInactive")} placement="right">
														<ToggleButton
															value={1}
															onClick={() => setChangeSupervisorFilter(1)}
															style={{
																color: !convSupervisorFilter && !isBotNul ? "black" : null,
																backgroundColor:
																	!convSupervisorFilter && !isBotNul ? "rgba(0, 0, 0, 0.15)" : null
															}}
														>
															<SupervisedUserCircleRoundedIcon fontSize="small" />
														</ToggleButton>
													</Tooltip>

													<Tooltip
														title={i18n.t("COC.supervisorFilterInactiveBot")}
														placement="right"
													>
														<ToggleButton
															value={0}
															onClick={() => setChangeSupervisorFilter(0)}
															style={{
																color: isBotNul ? "black" : null,
																backgroundColor: isBotNul ? "rgba(0, 0, 0, 0.15)" : null
															}}
														>
															<Android fontSize="small" style={{ color: "red" }} />
														</ToggleButton>
													</Tooltip>
												</ToggleButtonGroup>
											</Paper>
										</MenuList>
									</ClickAwayListener>
								)}
							</Popper>
						</Box>
					</Tooltip>
				)}
			{/* Remove the Mine/All convs filter from the Live(==3) tab */}
			{role === "agent" && !isCold && leftTab !== 0 && leftTab !== 3 && (
				<Tooltip title={i18n.t("COC.supervisorFilterConv")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredConv(true)}
						onMouseLeave={() => setHoveredConv(false)}
					>
						<ToggleButton
							aria-haspopup="menu"
							aria-controls={openConv ? "menu-list-grow-actions" : undefined}
							ref={anchorRefConv}
							value={openConv}
							size="small"
							style={{
								backgroundColor: convAgentFilter || isBotNul ? "rgba(0, 0, 0, 0.15)" : null
							}}
						>
							{!convAgentFilter && !isBotNul && (
								<SupervisedUserCircleRoundedIcon fontSize="small" />
							)}

							{convAgentFilter && !isBotNul && (
								<AccountCircleRoundedIcon fontSize="small" style={{ color: "black" }} />
							)}

							{isBotNul && <Android fontSize="small" style={{ color: "red" }} />}
						</ToggleButton>
						<Popper
							open={openConv}
							anchorEl={anchorRefConv.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseConv}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openConv}
										id="menu-list-grow-actions"
										onKeyDown={handleListKeyDownConv}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												exclusive
												size="small"
												background="#00000000"
												value={isBotNul ? 0 : convAgentFilter ? 1 : 2}
											>
												<Tooltip title={i18n.t("COC.supervisorFilterActive")} placement="right">
													<ToggleButton
														value={2}
														onClick={() => setChangeUserFilter(2)}
														style={{
															color: convAgentFilter && !isBotNul ? "black" : null,
															backgroundColor:
																convAgentFilter && !isBotNul ? "rgba(0, 0, 0, 0.15)" : null
														}}
													>
														<AccountCircleRoundedIcon fontSize="small" />
													</ToggleButton>
												</Tooltip>

												<Tooltip title={i18n.t("COC.supervisorFilterInactive")} placement="right">
													<ToggleButton
														value={1}
														onClick={() => setChangeUserFilter(1)}
														style={{
															color: !convAgentFilter && !isBotNul ? "black" : null,
															backgroundColor:
																!convAgentFilter && !isBotNul ? "rgba(0, 0, 0, 0.15)" : null
														}}
													>
														<SupervisedUserCircleRoundedIcon fontSize="small" />
													</ToggleButton>
												</Tooltip>

												<Tooltip
													title={i18n.t("COC.supervisorFilterInactiveBot")}
													placement="right"
												>
													<ToggleButton
														value={0}
														onClick={() => setChangeUserFilter(0)}
														style={{
															color: isBotNul ? "black" : null,
															backgroundColor: isBotNul ? "rgba(0, 0, 0, 0.15)" : null
														}}
													>
														<Android style={{ color: "red" }} fontSize="small" />
													</ToggleButton>
												</Tooltip>
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
			{/* Filtre LC */}
			{leftTab === 3 && <LCFilter />}
			{/* Actions de filtres */}
			{!isCold && (
				<FiltersActions setOpenDialog={setOpenDialog} setOpenDialogView={setOpenDialogView} />
			)}
			{/* Filtre conversation entrante/sortante */}
			{!isCold && <DirectionFilter />}
			{/* Filtre conversation value retournée grâce à un chemin d'accès du context */}
			{!isCold &&
				!lod_.isNil(cockpit?.showDictionaryPath) &&
				!lod_.isEmpty(cockpit?.showDictionaryPath) && (
					<PathContext pathDico={cockpit.showDictionaryPath} />
				)}
			<ListConvsAction openDialog={openDialog} setOpenDialog={setOpenDialog} />
			<ViewConversationDialog open={openDialogView} handleCloseDialog={handleCloseDialogView} />
		</Box>
	);
};

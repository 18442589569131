/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	TextField,
	IconButton,
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Select,
	InputLabel,
	FormControl,
	MenuItem
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import lod_ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { Autocomplete } from "@material-ui/lab";
import { loadSuggestionsEnrich } from "../business/searchDataInCrmCommons";
import { useSelector } from "react-redux";
import DisplayItem from "../DisplayItem";
import { replaceInTextHbr } from "helpers/utilities";

/**
 * Form specific: The dialog to create or update any object, with the data from conversation and build form with a dico from cluster client
 */
function InputType({
	value,
	config,
	language,
	path,
	customErrors,
	setCustomErrors,
	onChange,
	setValueDictionary
}) {
	useEffect(() => {
		switch (config.type) {
			case "boolean": {
				if (lod_.isNil(value)) {
					onChange(false, path, config);
				}
				break;
			}
			case "string":
			case "email": {
				if ((lod_.isNil(value) || lod_.isEmpty(value)) && config?.isRequired === true) {
					setCustomErrors(prev => {
						return {
							...prev,
							[path]: i18n.t(`COC.actionForm.FORMS.required`)
						};
					});
				}
				break;
			}
			default: {
				if (lod_.isNil(value) && config?.isRequired === true) {
					setCustomErrors(prev => {
						return {
							...prev,
							[path]: i18n.t(`COC.actionForm.FORMS.required`)
						};
					});
				}
				break;
			}
		}
	}, []);

	switch (config.type) {
		case "boolean":
			return (
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={value}
								disabled={config?.actions?.edit === false}
								onChange={e => onChange(e.target.checked, path, config)}
								name="conversations"
							/>
						}
						label={
							!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
								? config?.label[language]
								: path
						}
					/>
				</FormGroup>
			);
		case "code":
			return (
				<Box pt={2}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="select-label">
							{!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
								? config?.label[language]
								: path}
						</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={
								!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
									? config?.label[language]
									: path
							}
							disabled={config?.actions?.edit === false}
							value={value}
							onChange={e => onChange(e.target.value, path, config)}
						>
							{config.codes
								.sort((a, b) => a?.label.localeCompare(b?.label))
								.map((code, index) => (
									<MenuItem key={index} value={code.value}>
										{code.label}
									</MenuItem>
								))}
						</Select>
						<p style={{ color: "red" }}>{customErrors}</p>
					</FormControl>
				</Box>
			);
		case "number":
			return (
				<Box pt={2}>
					<TextField
						id="custom-number"
						type="number"
						label={
							!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
								? config?.label[language]
								: path
						}
						value={!lod_.isNil(value) ? value : ""}
						onChange={e => onChange(e.target.value, path, config)}
						fullWidth
						disabled={config?.actions?.edit === false}
						InputProps={{
							endAdornment: !lod_.isNil(value) && config?.actions?.edit !== false && (
								<IconButton
									onClick={() => {
										onChange(null, path, config);
									}}
									size="small"
								>
									<Close />
								</IconButton>
							)
						}}
						variant="outlined"
					/>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		case "string":
		case "email":
			if (
				!lod_.isNil(config?.whitelistDynamic) &&
				!lod_.isEmpty(config?.whitelistDynamic) &&
				!lod_.isNil(config?.whitelistDynamic?.config) &&
				!lod_.isEmpty(config?.whitelistDynamic?.config)
			) {
				return (
					<Box pt={2}>
						<InputTypeWD
							value={value}
							config={config}
							language={language}
							path={path}
							setValueDictionary={setValueDictionary}
							onChange={onChange}
						/>
					</Box>
				);
			} else {
				return (
					<Box pt={2}>
						<TextField
							id="custom-text"
							label={
								!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
									? config?.label[language]
									: path
							}
							value={value}
							onChange={e => onChange(e.target.value, path, config)}
							fullWidth
							disabled={config?.actions?.edit === false}
							InputProps={{
								endAdornment: !lod_.isEmpty(value) && config?.actions?.edit !== false && (
									<IconButton
										onClick={() => {
											onChange("", path, config);
										}}
										size="small"
									>
										<Close />
									</IconButton>
								)
							}}
							variant="outlined"
						/>
						<p style={{ color: "red" }}>{customErrors}</p>
					</Box>
				);
			}
		case "textarea":
			return (
				<Box pt={2}>
					<TextField
						id="custom-text"
						label={
							!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
								? config?.label[language]
								: path
						}
						value={value}
						onChange={e => onChange(e.target.value, path, config)}
						fullWidth
						disabled={config?.actions?.edit === false}
						InputProps={{
							endAdornment: !lod_.isEmpty(value) && config?.actions?.edit !== false && (
								<IconButton
									onClick={() => {
										onChange("", path, config);
									}}
									size="small"
								>
									<Close />
								</IconButton>
							)
						}}
						variant="outlined"
						multiline
						minRows={4}
						maxRows={4}
					/>
				</Box>
			);

		case "date":
			return (
				<Box pt={2}>
					<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
						<KeyboardDatePicker
							fullWidth
							inputVariant="outlined"
							format="dd/MM/yyyy"
							disabled={config?.actions?.edit === false}
							label={
								!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
									? config?.label[language]
									: path
							}
							value={!lod_.isNil(value) ? value : null}
							onChange={e => onChange(e, path, config)}
							disableToolbar
							onError={() => null}
							helperText=""
						/>
					</MuiPickersUtilsProvider>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		case "phone":
			return (
				<Box pt={2}>
					<MuiPhoneNumber
						fullWidth
						variant="outlined"
						id="custom-phone"
						label={
							!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
								? config?.label[language]
								: path
						}
						value={!lod_.isNil(value) ? value : ""}
						defaultCountry={"fr"}
						onChange={e => onChange(e, path, config)}
						disabled={config?.actions?.edit === false}
						disableDropdown={config?.actions?.edit === false}
						InputProps={{
							endAdornment: !lod_.isEmpty(value) && config?.actions?.edit !== false && (
								<IconButton
									onClick={() => {
										onChange(null, path, config);
									}}
									size="small"
								>
									<Close />
								</IconButton>
							)
						}}
					/>
					<p style={{ color: "red" }}>{customErrors}</p>
				</Box>
			);
		default:
			return <></>;
	}
}

function InputTypeWD({ value, config, language, path, onChange, setValueDictionary }) {
	const [searchField, setSearchField] = useState(value);
	const [suggestions, setSuggestions] = useState([]);
	const [options, setOptions] = useState([]);

	const { assistantID, user, selectedConversation } = useSelector(state => {
		return {
			assistantID: state.assistantconfig.assistantID,
			user: state.userStatus.auth.user,
			selectedConversation: state.cockpit.selectedConversation
		};
	});

	useEffect(() => {
		if (searchField) {
			const timeoutId = setTimeout(async () => {
				const suggestionsFetched = await loadSuggestionsEnrich(
					assistantID,
					user,
					searchField,
					config?.whitelistDynamic?.config?.host,
					config?.whitelistDynamic?.config?.sourceApi,
					selectedConversation?.context,
					config?.whitelistDynamic?.config?.searchPath,
					config?.whitelistDynamic?.config?.sourceAction
				);
				setSuggestions(suggestionsFetched);
			}, 500);
			return () => clearTimeout(timeoutId);
		} else {
			setSuggestions([]);
		}
	}, [searchField]);

	useEffect(() => {
		if (
			!lod_.isNil(config?.whitelistDynamic?.params?.searchFields) &&
			!lod_.isNil(config?.whitelistDynamic?.params?.attribute)
		) {
			setOptions([
				...config?.whitelistDynamic?.params?.searchFields,
				config?.whitelistDynamic?.params?.attribute
			]);
		} else if (!lod_.isNil(config?.whitelistDynamic?.params?.attribute)) {
			setOptions([config?.whitelistDynamic?.params?.attribute]);
		} else {
			setOptions([]);
		}
	}, []);

	async function handleAutocompleteChange(event, newInputValue, reason) {
		// This componenent manages its action by itself, but still call handleSubmit to close actions tabs

		if (newInputValue && reason === "select-option") {
			const valueInput = lod_.get(newInputValue, config?.whitelistDynamic?.params?.attribute);
			setSearchField(valueInput);

			if (
				!lod_.isNil(config?.whitelistDynamic?.config?.mapping?.out) &&
				!lod_.isEmpty(config?.whitelistDynamic?.config?.mapping?.out)
			) {
				let test = replaceInTextHbr(
					config?.whitelistDynamic?.config?.mapping?.out,
					newInputValue,
					true
				);
				setValueDictionary(prev => {
					let result = lod_.cloneDeep(prev);
					result = lod_.merge(result, test);
					if (lod_.isNil(result)) {
						return prev;
					}
					return result;
				});
			} else {
				onChange(valueInput, path, config);
			}
		}

		if (reason === "clear") {
			onChange("", path, config);
			setSearchField("");
		}
	}

	function handlInputChange(event) {
		setSearchField(event.target.value);
	}

	const getOptionLabel = option => {
		const label = option[config?.whitelistDynamic?.params?.attribute] || "";
		return label;
	};

	return (
		<Autocomplete
			id="autocomplete-recipient"
			freeSolo
			options={suggestions}
			style={{ minWidth: "300px" }}
			getOptionLabel={getOptionLabel}
			inputValue={searchField || ""}
			onChange={handleAutocompleteChange}
			filterOptions={(options, state) => options}
			renderOption={(suggestedValue, option) => DisplayItem(suggestedValue, options)}
			renderInput={params => (
				<TextField
					{...params}
					label={
						!lod_.isNil(config?.label) && !lod_.isNil(config?.label[language])
							? config?.label[language]
							: path
					}
					variant="outlined"
					size="medium"
					onChange={handlInputChange}
				/>
			)}
		/>
	);
}

export default InputType;

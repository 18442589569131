/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable default-case */

import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import cx from "classnames";
import i18n from "i18n";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { useEffect, useRef } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import userStatusActions from "../redux/actions/userStatusActions.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useFAibrikContext } from "../context/Context";
import FirstConnexion from "../pages/FirstConnexion/FirstConnexion";
import { useAuth0 } from "../react-auth0-wrapper";
import assistantconfigActions from "../redux/actions/assistantconfigActions";
import assistantsActions from "../redux/actions/assistantsActions.js";
import cockpitActions from "../redux/actions/cockpitActions";
import engageActions from "../redux/actions/engageActions.js";
import routesActions from "../redux/actions/routesActions.js";
import selectedAssistantActions from "../redux/actions/selectedAssistantActions";
import routes from "../routes/routes";
import faibrik_white from "../assets/img/logo/default/faibrik_white.png";
import C from "constants/cockpit";
import L from "constants/labels";
import releaseNotesActions from "../redux/actions/releaseNotesActions";
import DisplayReleaseNote from "../components/ReleaseNote/DisplayReleaseNote.js";

import axios from "axios";
import { getCockpitSelector } from "redux/selectors/cockpitSelectors.js";
import { initAnswers } from "redux/reducers/answersReducers.js";
import { initFilters } from "redux/reducers/filtersReducers.js";
// require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
	const { isAuthenticated, user, getTokenSilently } = useAuth0();
	const { ...rest } = props;
	const error = useSelector(state => state.error);
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [miniActive, setMiniActive] = React.useState(true);
	const [color] = React.useState("blue");
	const [bgColor] = React.useState("black");
	const [logo] = React.useState(faibrik_white);
	const [firstConnexion, setFirstConnexion] = React.useState(undefined);
	const [surname, setSurname] = React.useState(undefined);
	const [name, setName] = React.useState(undefined);
	const { fAibrikStatus } = useFAibrikContext();
	const userConnected = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;
	const userLanguage =
		props.userStatus && props.userStatus.auth && props.userStatus.auth.user.language;
	const dispatch = useDispatch();
	const { selectedAssistantID } = useSelector(getCockpitSelector, shallowEqual);

	// Release notes states
	const [releaseNotesDialogStatus, setReleaseNotesDialogStatus] = React.useState({ open: false });
	// const [isReleaseNotesViewed, setIsReleaseNotesViewed] = React.useState(false);
	const [isCockpitLastReleaseNotesView, setIsCockpitLastReleaseNotesView] = React.useState(false);

	// styles
	const classes = useStyles();
	const mainPanelClasses =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
		});

	const mainPanel = React.createRef();
	const prevAssistantIDRef = useRef();

	/**
	 * RESET STORE
	 */
	useEffect(() => {
		if (prevAssistantIDRef.current) {
			dispatch({ type: C.RESET_COCKPIT });
			dispatch({ type: "ws/reset" });
			//dispatch({ type: UG.RESET_USERGROUP }); //need a cleanup in the project before we can do that
			//dispatch({ type: US.RESET_USERSTATUS });
			dispatch({ type: L.RESET_LABELS });
			dispatch(initAnswers());
			dispatch(initFilters());
		}
		prevAssistantIDRef.current = selectedAssistantID;
	}, [selectedAssistantID]);

	React.useEffect(() => {
		if (firstConnexion !== undefined && !firstConnexion) {
			if (navigator.platform.indexOf("Win") > -1) {
				ps = new PerfectScrollbar(mainPanel.current, {
					suppressScrollX: true,
					suppressScrollY: false
				});
				if (document?.body?.style) document.body.style.overflow = "hidden";
			}
			window.addEventListener("resize", resizeFunction);
			// Specify how to clean up after this effect:
			return function cleanup() {
				if (navigator.platform.indexOf("Win") > -1) {
					ps.destroy();
				}
				window.removeEventListener("resize", resizeFunction);
			};
		}
	});

	/*
    userStatus initialization (accountID, token, perms)
  */
	React.useEffect(() => {
		if (isAuthenticated && user) {
			if (!user.token) {
				getTokenSilently()
					.then(token => {
						user.token = token;
					})
					/*eslint-disable-next-line no-unused-vars*/
					.then(_ => {
						if (!user.accountID) {
							fAibrikStatus.fetching(user, (firstConnexion, name, surname) => {
								setFirstConnexion(firstConnexion);
								setName(name);
								setSurname(surname);
							});
						}
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, user]);

	// *** RELEASE NOTES ***

	const getLastReleaseNote = () => {
		if (user.email && user.token) {
			return axios(endPoint + "/api/v1/getReleaseNotes", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "GET",
				params: { myAppID: "cockpit" }
			}).then(res => {
				return res.data;
			});
		}
	};

	// Don't show release note on this version
	useEffect(async () => {
		if (userConnected) {
			var open = false;
			var viewReleaseNoteAtOpening = userConnected.cockpit_release_note?.dont_show_at_opening;
			var isHideSessionReleaseNotes = sessionStorage.getItem("releaseNoteDisplayed");
			let lastReleaseNote = await getLastReleaseNote();
			let currentUserDate = userConnected.cockpit_release_note?.version;

			/**
			 * If there is no release note
			 */
			if (lastReleaseNote.error) {
				return;
			}

			/**
			 * Open the release note at every brower opening (if checkbox is not checked)
			 */
			if (!open) {
				if (!isHideSessionReleaseNotes || isHideSessionReleaseNotes === "false") {
					if (!(viewReleaseNoteAtOpening && viewReleaseNoteAtOpening === true)) {
						open = true;
						handleOpenDisplayReleaseNotes();
					}
				}
			}

			/**
			 * Open the release note when new release note is available
			 */
			if (!open && userConnected.cockpit_release_note) {
				if (currentUserDate != lastReleaseNote.date) {
					open = true;
					userConnected.cockpit_release_note.version = lastReleaseNote.date;
					handleOpenDisplayReleaseNotes();
				}
			}
		}
	}, [userConnected]);

	const handleOpenDisplayReleaseNotes = () => {
		setReleaseNotesDialogStatus({ open: true });
		const myAppID = "cockpit";
		props.releaseNotesActions.getReleaseNotes(myAppID);
	};

	const handleCloseDisplayReleaseNotes = (isReleaseNoteHiddenSelected, version) => {
		// if (isReleaseNoteHiddenSelected === true) {
		props.userStatusActions.hideReleaseNote(isReleaseNoteHiddenSelected, version);
		// }
		props.userStatusActions.hideSessionReleaseNote(true);
		setReleaseNotesDialogStatus({ open: false });
	};

	////////////////////////////////////////////////////////////////////////////////

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	const passwordChanged = () => {
		setFirstConnexion(false);
	};

	/**
	 * Display name active route on top left like Cockpit,Answer ...etc. otherwise display Default
	 * @param {*} routes
	 * @returns
	 */
	const getActiveRoute = routes => {
		let activeRoute = "Default";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);

				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return `${i18n.t(routes[i].name)}`;
				}
			}
		}
		return activeRoute;
	};

	/**
	 * check if there is a sub-array for one of the arrays, we apply the function recursively otherwise we display the array
	 */

	const getRoutes = (
		<Switch>
			{routes.map((prop, key) => {
				if (prop.collapse) {
					return prop.views.map((prop, key) => {
						return (
							<Route
								path={prop.layout + prop.path}
								exact
								render={routerProps => {
									return <prop.component {...routerProps} />;
								}}
								key={key}
							/>
						);
					});
				} else {
					return (
						<Route
							path={prop.layout + prop.path}
							exact
							render={routerProps => {
								return <prop.component {...routerProps} />;
							}}
							key={key}
						/>
					);
				}
			})}
		</Switch>
	);

	return (
		<div className={classes.wrapper} style={{ position: "relative", overflow: "auto" }}>
			{error && error.disconnect && (
				<div>
					<div
						style={{
							width: "100%",
							height: "100%",
							background: "white",
							opacity: 0.8,
							zIndex: 999,
							position: "absolute"
						}}
					></div>
					<div
						style={{
							width: "100%",
							height: "100%",
							background: "transparent",
							zIndex: 999,
							position: "absolute"
						}}
					>
						<Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
							<Alert severity={error.severity} elevation={6} variant="filled">
								{error.message}
							</Alert>
						</Snackbar>
					</div>
				</div>
			)}
			{isAuthenticated && firstConnexion !== undefined && (
				<div style={{ position: "relative", zIndex: 1 }}>
					{firstConnexion ? (
						<FirstConnexion name={name} surname={surname} passwordChanged={passwordChanged} />
					) : (
						<React.Fragment>
							<Sidebar
								logoText={"FAIbrik"}
								logo={logo}
								image={null}
								handleDrawerToggle={handleDrawerToggle}
								open={mobileOpen}
								color={color}
								bgColor={bgColor}
								miniActive={miniActive}
								handleOpenDisplayReleaseNotes={handleOpenDisplayReleaseNotes}
								brandText={getActiveRoute(routes)}
								{...rest}
							/>

							<div className={mainPanelClasses} ref={mainPanel}>
								<div className={classes.content}>
									<div className={classes.container}>{getRoutes}</div>
								</div>
							</div>

							<div className="releaseNote">
								{releaseNotesDialogStatus.open && (
									<DisplayReleaseNote
										checked={userConnected.cockpit_release_note?.dont_show_at_opening || false}
										open={releaseNotesDialogStatus.open}
										isCockpitLastReleaseNotesView={isCockpitLastReleaseNotesView}
										releaseNotesDialogStatus={releaseNotesDialogStatus}
										handleOpenDisplayReleaseNotes={handleOpenDisplayReleaseNotes}
										handleCloseDisplayReleaseNotes={handleCloseDisplayReleaseNotes}
										userLanguage={userLanguage}
									/>
								)}
							</div>
						</React.Fragment>
					)}
				</div>
			)}
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		assistantsActions: bindActionCreators(assistantsActions, dispatch),
		userStatusActions: bindActionCreators(userStatusActions, dispatch),
		selectedAssistantActions: bindActionCreators(selectedAssistantActions, dispatch),
		cockpitActions: bindActionCreators(cockpitActions, dispatch),
		routesActions: bindActionCreators(routesActions, dispatch),
		engageActions: bindActionCreators(engageActions, dispatch),
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch),
		releaseNotesActions: bindActionCreators(releaseNotesActions, dispatch)
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard);

/* eslint-disable react/prop-types */
import {
	Badge,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import i18n from "i18n";
import _lod from "lodash";
import GetChannelImage from "helpers/GetChannelImage";
import { getLabelOf } from "helpers/utilities";
import { handleSpecialDisplayDate } from "helpers/utilities";
import { formatDate } from "helpers/utilities";
import { api } from "redux/actions/api";
import cockpitActions from "../../../redux/actions/cockpitActions";

/**
 * Not used in v1.8
 * @param {} param0
 * @returns
 */
const ViewConversationDialog = ({ handleCloseDialog, open }) => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(state => state.cockpit);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);

	const assistantConfig = useSelector(state => state.assistantconfig);
	const user = useSelector(state => state.userStatus?.auth?.user);

	const { channels } = useSelector(state => state.assistantconfig);
	const [convs, setConvs] = useState([]);

	const displayTitleConv = conv => {
		if (user?.cockpitSettings?.defaultTopicOrSubject) {
			let title = user.cockpitSettings.defaultTopicOrSubject;
			let lastSubject = conv?.subject;

			if (title === "subject") {
				return lastSubject;
			} else if (title === "topic") {
				return getLabelOf(conv.meta?.topic);
			} else {
				return getLabelOf(conv.meta?.cockpit.title);
			}
		}

		if (conv.meta?.cockpit?.title) {
			return getLabelOf(conv.meta?.cockpit.title);
		}
	};

	useEffect(() => {
		if (open) {
			let role = user.role;

			const onSuccess = res => {
				if (!_lod.isNil(res) && !_lod.isEmpty(res)) {
					setConvs(res);
				} else {
					setConvs([]);
				}
			};

			dispatch(
				api({
					method: "POST",
					type: "cockpit/actionFrontCustom",
					url: `/api/v1/lastView/conversations/${selectedAssistantID}`,
					data: { role },
					onSuccess
				})
			);
		}
	}, [open]);

	const selectTargetConv = fID => {
		handleCloseDialog();
		dispatch(
			cockpitActions.selectConversation({
				header: {
					assistantID: selectedAssistantID,
					fID
				}
			})
		);
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{i18n.t("COC.lastViewConv")}</DialogTitle>
			<DialogContent dividers>
				{_lod.isEmpty(convs) ? (
					<Box display="flex" alignItems="center" width={1} justifyContent="center">
						<Typography>{i18n.t("COC.noElementConv")}</Typography>
					</Box>
				) : (
					<Box>
						<List>
							{convs.map((item, index) => (
								<>
									<Box style={{ display: "flex" }}>
										<ListItem
											key={index}
											button
											onClick={() => selectTargetConv(item?.header?.fID)}
										>
											<ListItemAvatar>
												<img
													src={GetChannelImage(
														channels,
														item.meta?.cockpit?.channelCode,
														item?.header?.channel?.toUpperCase()
													)}
													alt="Channel-logo"
													width="30"
													height="30"
													className="logo-channel"
												/>
											</ListItemAvatar>
											<ListItemText
												primary={displayTitleConv(item) + " " + `(${item?.meta?.cockpit?.contact})`}
												secondary={
													(assistantConfig?.cockpit?.datetime_format?.format
														? handleSpecialDisplayDate(
																item?.meta?.cockpit?.ts,
																assistantConfig?.cockpit?.datetime_format?.format,
																user?.language?.toLowerCase() || "fr"
															)
														: formatDate(item?.meta?.cockpit?.datetime)) +
													" | " +
													item?.header?.fID
												}
											/>
										</ListItem>
									</Box>
								</>
							))}
						</List>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog} color="primary">
					{`${i18n.t("COC.Cancel")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ViewConversationDialog;

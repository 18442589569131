import React, { useEffect, useRef, useState } from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { defaultStyles, FileIcon } from "react-file-icon";
import FileActions, { getFileExtension, mimeTypeCompute } from "./actions/FileActions";
import lod_ from "lodash";

/**
 * Attachement with a preview
 * If this is an image, it will display the image
 * Otherwise, it will display the file icon
 * @param {object} props.attachement - The attachment object
 * @returns {JSX.Element} - The attachment item
 */
const AttachmentPreview = ({ attachement, mID, url, download }) => {
	let attachementType = attachement.contentType || attachement.type;
	const textRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		if (textRef.current) {
			setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
		}
	}, []);
	return (
		<Box
			style={{
				borderRadius: "5px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-between",
				border: "1px solid #ccc",
				backgroundColor: "white",
				height: "100%"
			}}
			sx={{ p: 1 }}
		>
			{attachementType?.includes("image") ? (
				<Box
					component="img"
					alt={attachement.name}
					src={attachement.url}
					style={{
						width: "100%",
						height: "auto",
						maxHeight: "200px",
						objectFit: "contain"
					}}
				/>
			) : (
				<div
					style={{
						width: "100%",
						height: "auto",
						maxHeight: "200px",
						objectFit: "contain"
					}}
				>
					<FileIcon
						extension={getFileExtension(attachement.name).replace(".", "")}
						{...defaultStyles[getFileExtension(attachement.name).replace(".", "").toLowerCase()]}
					/>
				</div>
			)}

			<Box
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					overflow: "hidden"
				}}
			>
				<Tooltip
					title={isOverflowing ? attachement.name : ""}
					disableHoverListener={!isOverflowing}
				>
					<Typography
						variant="body2"
						component="p"
						ref={textRef}
						style={{
							opacity: 0.7,
							fontSize: "12px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							maxWidth: "80%"
						}}
					>
						{attachement.name}
					</Typography>
				</Tooltip>
				<Box>
					<FileActions
						mID={mID}
						attachment={{
							// Default attachment attributes
							...attachement,
							// Need to recompute this attributes
							url: url,
							mimeType: mimeTypeCompute(attachement)
						}}
						customDownloadProcess={download}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default AttachmentPreview;

import Box from "@material-ui/core/Box";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import i18n from "i18n";
import C from "../../../constants/cockpit";
import lod_ from "lodash";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import BuiltInAnswer from "./BuiltInAnswer";
import { setSortedBuiltInAnswers } from "redux/reducers/answersReducers";
import { setAnswerLanguage } from "redux/reducers/answersReducers";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";

/**
 * List of BuiltInAnswers
 */
export const Answers = ({
	insertRichAnswerToEditor,
	insertConvAnswerToEditor,
	tagSelected,
	setTagsResponses,
	setTag,
	onlyViewRecommandedResponse = false,
	selectedResponse,
	setSelectedResponse
}) => {
	const dispatch = useDispatch();
	const answers = useSelector(state => state.answers.data);
	const { language, contactAnsDocSearchFilter, sortedBuiltInAnswers } = useSelector(
		state => state.answers
	);
	const { selectedConversation } = useSelector(state => state.cockpit);
	const answersLength = answers?.length || 0;

	const [loadingBuiltin, setLoadingBuiltin] = useState(`panel_${-1}`);
	const [allowInsertMessage, setAllowInsertMessage] = useState(true);

	const conversationChannel = selectedConversation?.header.channel;
	const conversationChannelCode = selectedConversation?.meta?.cockpit?.channelCode;
	const [recommendedResponses, setRecommendedResponses] = useState([]);
	const [channelCheckCode, setChannelCheckCode] = useState(
		selectedConversation?.meta?.cockpit?.channelCode
	);

	/**
	 * When conversation change, put the answer item in the conversation language by default
	 */
	useEffect(() => {
		dispatch(
			setAnswerLanguage(
				selectedConversation?.header.language?.toUpperCase() ||
					(selectedConversation?.messages &&
						selectedConversation?.messages[0]?.header?.language?.toUpperCase()) ||
					""
			)
		);
	}, [selectedConversation?.header?.fID]);

	/**
	 * Get Answer Language & recommended response from prediction
	 */
	useEffect(() => {
		if (
			sortedBuiltInAnswers &&
			sortedBuiltInAnswers.length > 0 &&
			selectedConversation?.meta?.predictions &&
			selectedConversation?.meta?.predictions.length > 0
		) {
			let newRecommendedResponse = [];
			selectedConversation?.meta?.predictions.forEach(prediction => {
				let element = sortedBuiltInAnswers.find(answer => answer.name === prediction.code);
				if (!lod_.isNil(element)) {
					newRecommendedResponse.push(element);
				}
			});
			setRecommendedResponses(newRecommendedResponse);
		} else {
			setRecommendedResponses([]);
		}
	}, [selectedConversation?.header?.fID, sortedBuiltInAnswers]);

	const buildSortedBuiltInAnswers = () => {
		if (answersLength > 0) {
			let sorted = [];
			let tag = [];

			answers.map(answer => {
				switch (answer.version) {
					case "2.0": {
						// 1- Keep only answer type & type same as conversation's type
						if (answer.documentType !== "answerType") {
							break;
						}

						// 2- Do not include answers that are not as same as conversation's type
						if (answer.type !== selectedConversation?.header.type) {
							break;
						}

						// 3- Map answer content
						(answer.content ?? []).map((content, index) => {
							if (
								filtersAlternativesV2(answer, content) &&
								!C.NOT_DISPLAYABLE_ANSWER_ITEM.includes(answer.code)
							) {
								sorted.push({
									...answer,
									alternativeIndex: index,
									content: content
								});

								tag = lod_.union(tag, answer.tags ?? []);
							}
						});
						break;
					}
					default: {
						// Map alternatives
						(answer.alternatives ?? []).forEach(alternative => {
							// Map alternatives answers
							if (
								alternativePassTheFilters(alternative) &&
								!C.NOT_DISPLAYABLE_ANSWER_ITEM.includes(alternative.name)
							) {
								sorted.push(alternative);
							}

							// Map alternatives tags
							if (
								alternativePassTheFilters(alternative, true) &&
								!C.NOT_DISPLAYABLE_ANSWER_ITEM.includes(alternative.name)
							) {
								tag = lod_.union(tag, alternative.tags);
							}
						});
						break;
					}
				}
			});

			sorted.sort((a, b) => {
				/**
				 * Sort by alphabetic order
				 */
				let aAnswer = a.description && a.description !== "" ? a.description : a.name || "";
				let bAnswer = b.description && b.description != "" ? b.description : b.name || "";

				return aAnswer.localeCompare(bAnswer);
			});

			if (
				selectedConversation?.messages &&
				selectedConversation?.messages[0]?.understand?.proposedAnswers
			) {
				let proposedAnswers = selectedConversation?.messages[0]?.understand?.proposedAnswers;

				Object.entries(proposedAnswers)
					.filter(proposedAnswerEntry => proposedAnswerEntry[0] === language)
					.forEach(([language, answers]) => {
						answers.forEach(answer => {
							let obj = {
								type: "review",
								languages: [language],
								content: [{ language, content: { text: answer } }],
								description: i18n.t("COC.suggested_answer"),
								priority: 1,
								open: true
							};

							sorted.push(obj);
						});
					});
			}

			if (channelCheckCode !== conversationChannelCode) {
				setChannelCheckCode(conversationChannelCode);
				setTag("none");
			}

			if (tagSelected !== "none" && !lod_.isEmpty(tag)) {
				sorted = sorted.filter(tagResponse => tagResponse.tags.includes(tagSelected));
			}
			setTagsResponses(tag);
			dispatch(setSortedBuiltInAnswers(sorted));
		}
	};

	/**
	 * On filter's changes
	 */
	useEffect(() => {
		buildSortedBuiltInAnswers();
	}, [
		answersLength,
		language,
		contactAnsDocSearchFilter,
		selectedConversation?.header.fID,
		tagSelected
	]);

	/**
	 * Answer items filters : activated, build_in, channel, search, language
	 * @param {*} alternative
	 * @returns
	 */
	const alternativePassTheFilters = (alternative, byPassForTag = false) => {
		const alternativeContentLanguage = lod_.findIndex(alternative.content, { language });

		const builtInCondition = alternative.built_in;
		const activatedCondition = alternative.activated;
		let channelCondition = false;
		if (conversationChannelCode) {
			//accept ML: [] and ML:[conversationChannelCode,...]
			if (
				alternative.channels[conversationChannel]?.length > 0 ||
				Object.keys(alternative.channels).includes(conversationChannel)
			) {
				//if alternative has channelCode(s)
				channelCondition = true;
			}
		} else {
			//accept only ML:[]
			if (Object.keys(alternative.channels).includes(conversationChannel)) channelCondition = true;
		}

		// On bypass la condition de search si c'est pour les tags ou si le filtre est vide
		const byPassSearchCondition =
			byPassForTag || contactAnsDocSearchFilter?.trim()?.toLowerCase().length === 0;

		let searchCondition = byPassSearchCondition;

		// Sinon on cherche dans le nom, la description ou le contenu
		if (!byPassSearchCondition) {
			const lowerCaseFilter = contactAnsDocSearchFilter?.toLowerCase();

			// Recherche dans le nom
			if (alternative.name && alternative.name?.toLowerCase().includes(lowerCaseFilter)) {
				searchCondition = true;
			}

			// Recherche dans la description
			if (
				!searchCondition &&
				alternative.description &&
				alternative.description?.toLowerCase().includes(lowerCaseFilter)
			) {
				searchCondition = true;
			}

			// Recherche dans le contenu
			if (
				!searchCondition &&
				alternative.content &&
				alternative.content[alternativeContentLanguage]
			) {
				const contentObj = alternative.content[alternativeContentLanguage]?.content;
				if (contentObj) {
					if (contentObj.text && contentObj.text?.toLowerCase().includes(lowerCaseFilter)) {
						searchCondition = true;
					}
					// On pourrait ajouter ici d'autres champs de recherche si nécessaire
				}
			}
		}

		const languageCondition =
			language?.length > 0
				? alternative.languages.includes(language)
				: alternative.languages.includes(selectedConversation?.header.language);

		return (
			channelCondition &&
			searchCondition &&
			languageCondition &&
			builtInCondition &&
			activatedCondition
		);
	};

	/**
	 * Filter all alternatives and return only the ones that pass the filters
	 * Filters : active, cockpit, channel, search, language
	 * Answeritems v2
	 * @param {Object} answer
	 * @param {Object} alternative
	 * @param {Boolean} byPassForTag
	 * @returns
	 */
	const filtersAlternativesV2 = (answer, alternative, byPassForTag = false) => {
		// 0- By pass for tag
		if (byPassForTag) {
			return true;
		}

		// 1- Check if answer is active & is for cockpit
		if (!answer.active || !answer.cockpit) {
			return false;
		}

		// 2- Check if alternative exists for the selected language
		const correctLanguage = lod_.get(alternative, language);

		if (!correctLanguage) {
			return false;
		}

		// 3- Check channel code. Answer is allowed for "ALL" channels or for the current channel
		let channelsCode = answer.channelsCode;
		if (!channelsCode.includes("ALL") && !channelsCode.includes(conversationChannelCode)) {
			return false;
		}

		// 4- Check if any content exists for the selected format
		// We accept all formats (text, document, survey, link, contact, location, locationRequest, etc.)
		const hasContent = Boolean(
			correctLanguage.text ||
				correctLanguage.document ||
				correctLanguage.survey ||
				correctLanguage.link ||
				correctLanguage.contact ||
				correctLanguage.location ||
				correctLanguage.locationRequest
		);

		// 5- Search
		if (!contactAnsDocSearchFilter?.toLowerCase()?.trim()) {
			return true;
		}
		let searchName = !answer.name
			? false
			: answer.name?.toLowerCase().includes(contactAnsDocSearchFilter?.toLowerCase());

		let searchDescription = !answer.description
			? false
			: answer.description?.toLowerCase().includes(contactAnsDocSearchFilter?.toLowerCase());

		// Also search in text content if it exists
		let searchText = !correctLanguage?.text
			? false
			: correctLanguage.text.toLowerCase().includes(contactAnsDocSearchFilter?.toLowerCase());

		if (searchName || searchDescription || searchText) {
			return true;
		}

		return false;
	};

	/**
	 *
	 * @param {*} message
	 * @returns
	 */
	const emptyMessage = message => {
		return (
			<Box
				height="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
				fontStyle="italic"
			>
				{message}
			</Box>
		);
	};

	/**
	 *
	 * @param {*} ans
	 */
	const handleReplaceReview = ans => {
		try {
			insertConvAnswerToEditor(ans.text);
		} catch {
			insertRichAnswerToEditor(ans.text);
		}
	};

	/**
	 *
	 * @param {*} isSuccess
	 * @param {*} isRichAnswer
	 * @param {*} ansContent
	 */
	const handleReplaceRichAnswerCallback = (isSuccess, isRichAnswer, ansContent) => {
		setLoadingBuiltin(`panel_${-1}`);

		if (allowInsertMessage) {
			if (!isSuccess) {
				dispatch(errorMsg(i18n.t("COC.renderMessageFailed")));
			}
			if (isRichAnswer) {
				// Prise en charge des types de contenu variés
				const text = ansContent.text || "";
				const attachments =
					ansContent.attachments || ansContent.document?.payload?.payload?.attachments || [];
				const subject = ansContent.subject || "";

				insertRichAnswerToEditor(text, attachments, subject);
			} else {
				insertConvAnswerToEditor(ansContent);
			}
		}
	};

	/**
	 *
	 * @param {*} panelIdx
	 * @param {*} ansCode
	 * @param {*} user
	 * @param {*} selectedConversation
	 * @param {*} ansContent
	 */
	const handleReplaceAnswer = (panelIdx, ansUid, ansCode, user, ansContent, alternativeIndex) => {
		let isRichAnswer = false;
		setAllowInsertMessage(true);
		setLoadingBuiltin(`panel_${panelIdx}`);
		dispatch(
			cockpitActions.renderMessage(
				ansCode,
				ansUid,
				user,
				language,
				isRichAnswer,
				false,
				null,
				ansContent,
				alternativeIndex,
				handleReplaceRichAnswerCallback
			)
		);
	};

	/**
	 *
	 * @param {*} panelIdx
	 * @param {*} ansCode
	 * @param {*} user
	 * @param {*} selectedConversation
	 * @param {*} ans
	 */
	const handleReplaceRichAnswer = (panelIdx, ansUid, ansCode, user, ans, alternativeIndex) => {
		let isRichAnswer = true;
		setAllowInsertMessage(true);
		setLoadingBuiltin(`panel_${panelIdx}`);
		dispatch(
			cockpitActions.renderMessage(
				ansCode,
				ansUid,
				user,
				language,
				isRichAnswer,
				false,
				null,
				ans,
				alternativeIndex,
				handleReplaceRichAnswerCallback
			)
		);
	};
	if (onlyViewRecommandedResponse === true) {
		return (
			<Box>
				{!lod_.isNil(recommendedResponses) && !lod_.isEmpty(recommendedResponses) ? (
					<Box>
						{recommendedResponses.length > 0 &&
							recommendedResponses.map((builtInAnswer, index) => (
								<Box key={index} style={{ display: "flex", alignItems: "center" }}>
									<Box style={{ width: "100%" }}>
										<BuiltInAnswer
											key={builtInAnswer.uid + index}
											ans={builtInAnswer}
											indexaccordion={index}
											handleReplaceReview={handleReplaceReview}
											handleReplaceAnswer={
												selectedConversation?.header.type === "ticket"
													? handleReplaceRichAnswer
													: handleReplaceAnswer
											}
											useAction={false}
											loadingBuiltin={loadingBuiltin}
										/>
									</Box>
									<Box>
										<Checkbox
											checked={selectedResponse.includes(builtInAnswer.name)}
											color="primary"
											onChange={event => {
												setSelectedResponse(event, builtInAnswer.name);
											}}
										/>
									</Box>
								</Box>
							))}
					</Box>
				) : (
					<Box>{i18n.t("COC.empty_built_in_answer")}</Box>
				)}
			</Box>
		);
	} else {
		return (
			<Box height={sortedBuiltInAnswers.length == 0 ? "100%" : "auto"}>
				{recommendedResponses && recommendedResponses.length > 0 && (
					<>
						<Box
							style={{
								padding: "10px",
								marginBottom: "10px",
								backgroundColor: "#E7E7E7"
							}}
						>
							<Typography>Suggestions</Typography>
							{recommendedResponses.length > 0 &&
								recommendedResponses.map((builtInAnswer, index) => (
									<BuiltInAnswer
										key={builtInAnswer.uid + index}
										ans={builtInAnswer}
										indexaccordion={index}
										handleReplaceReview={handleReplaceReview}
										handleReplaceAnswer={
											selectedConversation?.header.type === "ticket"
												? handleReplaceRichAnswer
												: handleReplaceAnswer
										}
										loadingBuiltin={loadingBuiltin}
									/>
								))}
						</Box>
					</>
				)}
				<Box style={{ margin: "10px" }}>
					{sortedBuiltInAnswers.length > 0 &&
						sortedBuiltInAnswers.map((builtInAnswer, index) => (
							<BuiltInAnswer
								key={index}
								ans={builtInAnswer}
								indexaccordion={index}
								handleReplaceReview={handleReplaceReview}
								handleReplaceAnswer={
									selectedConversation?.header.type === "ticket"
										? handleReplaceRichAnswer
										: handleReplaceAnswer
								}
								loadingBuiltin={loadingBuiltin}
							/>
						))}
				</Box>
				{sortedBuiltInAnswers.length == 0 && emptyMessage(i18n.t("COC.empty_built_in_answer"))}
			</Box>
		);
	}
};

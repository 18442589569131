/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Button, Popper } from "@material-ui/core";
import useStyles from "./CockpitStyleJs/styleFilter";
import { getPriorityIcon, getSentimentIcon, getLabelOf } from "./../../helpers/utilities";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import cockpitActions from "../../redux/actions/cockpitActions";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, compose } from "redux";
import lod_ from "lodash";
import i18n from "i18n";
import UpdateConvIntentDialog from "./Convs/UpdateConvIntentDialog";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import { shallowEqual } from "helpers/utilities";
import { Add, WarningRounded } from "@material-ui/icons";

/**
 * display priority(+ update priority) + intent + sentiment
 * don't display the dropdown for priority edition in cockpitArchived page
 * @param {*} props
 * @returns
 */
function PriorityIntentSentimentDisplayer(props) {
	const { isCockpitArchived } = useSelector(state => state.cockpit);
	const { assistantconfig } = useSelector(state => state);
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [priorityAsc, setPriorityAsc] = React.useState(false);
	const open = Boolean(anchorEl);
	const id = open ? "PriorityIntentSentimentDisplayer" : undefined;
	const dispatch = useDispatch();
	// Handle rule to display ASC priority
	useEffect(() => {
		if (props.assistantconfig.cockpit) {
			if (lod_.has(props.assistantconfig.cockpit, "update_priority_rule")) {
				//Compatibility with ancien & new config format
				if (
					props.assistantconfig.cockpit.update_priority_rule === "asc" ||
					props.assistantconfig.cockpit.update_priority_rule === true
				) {
					setPriorityAsc(true);
				}
			}
		}
	}, [props.assistantconfig.cockpit]);

	// Handle Open/close dropdown menu
	const handleOpenCloseUpdatePriorityDropDown = event => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	//Update the selected conversation priority
	const handleUpdatePriority = value => {
		let selectedPriority = value;
		props.cockpitActions.updateConvPriority(props.assistantID, props.fID, selectedPriority);
		handleOpenCloseUpdatePriorityDropDown();
	};

	// Handle Display tooltip for priority
	const getPriorityTooltip = priority => {
		switch (priority) {
			case 1:
				return i18n.t("convItemPriority.minimal");
			case 2:
				return i18n.t("convItemPriority.low");
			case 3:
				return i18n.t("convItemPriority.medium");
			case 4:
				return i18n.t("convItemPriority.high");
			case 5:
				return i18n.t("convItemPriority.critical");

			default:
		}
	};

	const [openUpdateConvIntentDialog, setOpenUpdateConvIntentDialog] = useState(false);
	const { intents, userStatus, selectedAssistantID, convHistory, selectedConversation } =
		useSelector(getMesssageItemSelector, shallowEqual);

	const handleCloseUpdateConvIntentDialog = () => {
		setOpenUpdateConvIntentDialog(false);
	};
	const handleOpenUpdateConvIntentDialog = () => {
		setOpenUpdateConvIntentDialog(true);
	};

	const handleUpdateMessageIntent = (selectedIntent, topicCategory) => {
		dispatch(
			cockpitActions.updateTopic(props.assistantID, props.fID, selectedIntent, topicCategory)
		);
	};

	const isCold = props.parentName.includes("COLD"); // If cold don't propose certain modification action

	let titleComponent = <></>;

	if (props.title) {
		titleComponent = (
			<Box
				component="span"
				mx={0.5}
				style={{ paddingTop: 3, fontFamily: "Roboto", fontSize: "16px" }}
				onClick={null}
			>
				{getLabelOf(props.title)}
			</Box>
		);

		if (!isCold) {
			titleComponent = (
				<Tooltip title={i18n.t("COC.UpdateTopic")} placement="top">
					<Box
						component="span"
						mx={0.5}
						style={{ paddingTop: 3, fontFamily: "Roboto", fontSize: "16px", cursor: "pointer" }}
						onClick={() => handleOpenUpdateConvIntentDialog()}
					>
						{getLabelOf(props.title)}
					</Box>
				</Tooltip>
			);
		}
	} else if (selectedConversation?.header?.type !== "review") {
		titleComponent = (
			<Button
				sx={{ ml: 1, mr: 1 }}
				color="primary"
				size="small"
				variant="outlined"
				onClick={() => handleOpenUpdateConvIntentDialog()}
				startIcon={<Add />}
			>
				Ajouter un topic
			</Button>
		);
	} else if (selectedConversation?.header?.type === "review") {
		titleComponent = (
			<Box
				component="span"
				mx={0.5}
				style={{ paddingTop: 3, fontFamily: "Roboto", fontSize: "16px" }}
			>
				{selectedConversation.meta?.cockpit?.title}
			</Box>
		);
	} else {
		<></>;
	}

	return (
		<Box
			fontWeight="fontWeightBold"
			display="flex"
			data-component-name="PriorityIntentSentimentDisplayer"
			style={{
				display: "flex",
				alignItems: "center",
				marginBottom: 5
			}}
		>
			{props.priority && (
				<Box
					style={{
						display: "flex",
						alignItems: "center"
					}}
				>
					{!isCockpitArchived ? (
						<>
							<Tooltip title={i18n.t("COC.UpdatePriority")} placement="top">
								<Box
									className={classes.filterButton}
									aria-describedby={id}
									onClick={handleOpenCloseUpdatePriorityDropDown}
									style={{
										cursor: "pointer",
										display: "flex",
										alignItems: "center"
									}}
								>
									{getPriorityIcon(props.priority)}
								</Box>
							</Tooltip>

							<Popper
								id={id}
								open={open}
								anchorEl={anchorEl}
								className={classes.dropDown}
								style={{ zIndex: 999 }}
								transition
								disablePortal
							>
								{() => (
									<ClickAwayListener onClickAway={handleOpenCloseUpdatePriorityDropDown}>
										<MenuList id="update-priority">
											<Paper>
												<ToggleButtonGroup
													orientation="vertical"
													exclusive
													size="small"
													value={props.priority}
												>
													{[...Array(5)].map(
														(e, i) =>
															!(priorityAsc && i + 1 < props.priority) && (
																<Tooltip
																	key={i}
																	title={getPriorityTooltip(i + 1)}
																	placement="right"
																>
																	<ToggleButton
																		value={i + 1}
																		onClick={() => handleUpdatePriority(i + 1)}
																	>
																		{getPriorityIcon(i + 1)}
																	</ToggleButton>
																</Tooltip>
															)
													)}
												</ToggleButtonGroup>
											</Paper>
										</MenuList>
									</ClickAwayListener>
								)}
							</Popper>
						</>
					) : (
						getPriorityIcon(props.priority)
					)}
				</Box>
			)}
			{titleComponent}
			{assistantconfig.cockpit?.topicMandatory &&
				!["review", "conversational"].includes(selectedConversation.header.type) &&
				(!selectedConversation.meta.topic ||
					selectedConversation.meta.topic === "nointent" ||
					!assistantconfig.intents.some(obj => obj.name === selectedConversation.meta.topic)) && (
					<Tooltip title={i18n.t("COC.noTopic")}>
						<WarningRounded style={{ color: "red" }} />
					</Tooltip>
				)}

			<UpdateConvIntentDialog
				intents={intents}
				open={openUpdateConvIntentDialog}
				topic={true}
				handleUpdateMessageIntent={handleUpdateMessageIntent}
				handleCloseUpdateConvIntentDialog={handleCloseUpdateConvIntentDialog}
			/>
			{props.sentiment && (
				<Box component="span" mx={0.5} style={{ paddingTop: 3 }}>
					{getSentimentIcon(props.sentiment)}
				</Box>
			)}
		</Box>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}
export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(PriorityIntentSentimentDisplayer);
